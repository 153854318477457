$primary: #4296b2;
$custom-grey-1: #3f3f3f;
$custom-grey-2: #333;
$toolbarHeight: 43px;
$popover-header-bg: $primary;
$popover-header-color: #FFF;
$carousel-control-color: $primary;
@import "~bootstrap/scss/bootstrap";

body {
  &.opened {
    overflow: hidden;
  }
}

.axeptio_mount {
  z-index: 99 !important;
}

#hamburger {
  position: fixed;
  top: 10px;
  left: 10px;
  width: 40px;
  height: 40px;
  background: #FFF;
  z-index: 2;

  &-close {
    text-align: right;
    padding: 10px 15px;
  }
}

.toggle-content {
  @include media-breakpoint-down(sm) {
    position: fixed !important;
    overflow: hidden;
    transition: 0.5s;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 0;
    background: #FFF;
    z-index: 700;
    padding: 0;
    &.open {
      width: 100%;
    }
  }
}

#top-number {
  background-color: $custom-grey-2;
  color: #919191;
  font-size: 11px;
  padding: 5px 0 5px 80px;
  @include media-breakpoint-down(sm) {
    padding-left: 45px;
    span {
      display: block;
    }
  }
}

#toolbar {
  position: sticky;
  background: #FFF;
  z-index: 100;
  top: 0;
  @include media-breakpoint-up(sm) {
    box-shadow: 0 5px 5px rgb(0 0 0 / 20%);

    #login-box {
      position: relative;
    }
  }
  @include media-breakpoint-down(sm) {
    background-color: $primary;
    #login-box {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 0;
      width: 100%;
      text-align: center;

    }
    ul {
      padding: 0;
    }
  }

  .menu {
    height: 100%;
    @include media-breakpoint-down(sm) {
      background-color: $primary;
    }

    &-block {
      padding: 0;
      margin: 0;
      @include media-breakpoint-up(sm) {
        display: flex;
        height: 100%;
      }

      &-flat {
        .menu {
          &-link {
            display: block;
          }
        }
      }
    }

    &-sub {
      @include media-breakpoint-up(sm) {
        display: none;
        position: absolute;
        width: 100%;
        left: 0;
        top: $toolbarHeight;
        min-height: 80px;
        background: $primary;
        box-shadow: 0 5px 5px rgb(0 0 0 / 20%);
        padding: 40px;
        .menu-link {
          color: #FFF;
        }
      }
      @include media-breakpoint-down(sm) {
        padding-left: 15px;
        ul {
          padding-left: 15px;
        }
      }
    }

    &-item {
      @include media-breakpoint-up(sm) {
        display: flex;
        flex: 1 0 auto;
        height: 100%;
        &:hover {
          & > .menu-sub {
            display: block;
          }

          & > .menu-link {
            background-color: $primary;
            color: #FFF;
          }
        }
      }
      padding: 0;
      list-style: none;

      &-flat {
        display: block;

        .menu-link {
          justify-content: start;
        }
      }
    }

    &-link {
      @include media-breakpoint-up(sm) {
        display: flex;
      }
      @include media-breakpoint-down(sm) {
        background-color: $primary;
        color: #FFF;
        display: block;
        padding: 10px;
        border-bottom: 1px solid #FFF;
      }
      width: 100%;
      align-items: center;
      justify-content: center;
      color: #000;
      text-decoration: none;
    }
  }

  .short-login {
    padding: 0 10px;
    cursor: pointer;
    display: flex;
    height: 100%;
    align-items: center;
    @include media-breakpoint-down(sm) {
      text-align: center;
      justify-content: center;
      width: 50%;
      background: $primary;
      padding: 15px 0;
      color: #FFF;
      a {
        color: #FFF;
      }
    }

    a {
      display: block;
      width: 100%;
      text-decoration: none;
    }

    &:hover, &.open {
      background: $primary;
      color: #FFF;

      a {
        color: #FFF;
      }

      svg {
        color: #FFF;
      }
    }

    &.open {
      form {
        display: block;
      }
    }

    .account-link, .logout-link {
      align-items: center;
      display: flex;
      justify-content: center;

      > svg {
        margin-right: 5px;
      }
    }

    svg {
      color: $primary;
      width: 20px;
      margin-right: 5px;
      vertical-align: middle;
      @include media-breakpoint-down(sm) {
        color: #FFF;
      }
    }

    form {
      position: absolute;
      left: 0;
      display: none;
      color: #000;
      @include media-breakpoint-up(sm) {
        top: $toolbarHeight;
      }
      @include media-breakpoint-down(sm) {
        bottom: 60px;
        width: 100%;
      }

      .card {
        &-header {
          background-color: $primary;
          color: #FFF;
          font-weight: 500;
        }

        a {
          color: $primary;
        }
      }
    }
  }
}

#content {
  position: relative;
}

#footer {
  padding: 30px 0;
  background-color: $custom-grey-1;
  color: #919191;

  &2 {
    padding: 15px 0;
    background-color: $custom-grey-2;
    color: #919191;
    font-size: 11px;
  }

  .city-advisable {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid $custom-grey-2;

    ul {
      padding-left: 15px;
      column-count: 3;
      column-gap: 20px;
    }
  }
}

#footer, #footer2 {
  a {
    color: #919191;
    text-decoration: none;
  }

  ul {
    padding-left: 5px;

    li {
      list-style: none;
    }
  }

  .menu {
    &-block {
      &-depth1 {
        display: flex;
        flex-wrap: wrap;
        height: 100%;
        padding: 0;
        margin: 0;
        @include media-breakpoint-down(sm) {
          align-items: center;
          flex-direction: column;
          padding: 5px 0;
        }

        > .menu-item {
          flex: 1 0 auto;
          padding: 0;
        }
      }
    }
  }
}

.slogan {
  padding: 30px;
  text-align: center;
  font-weight: 500;
  font-size: 18px;

  > span {
    &:before {
      margin-right: 8px;
    }

    &:after {
      margin-left: 8px;
    }

    &:before, &:after {
      display: inline-block;
      content: '';
      width: 30px;
      height: 2px;
      background-color: $primary;
      vertical-align: middle;
    }
  }

  .baseline {
    font-size: 36px;
  }
}

.flag {
  &-fr {
    padding-top: 12px;

    span {
      display: inline-block;
      @include media-breakpoint-down(sm) {
        width: 70px;
      }
      width: 80px;
      height: 10px;
      background-color: #FFF;
    }

    &:before {
      background-color: #124693;
    }

    &:after {
      background-color: #cd091a;
    }

    &:before, &:after {
      display: inline-block;
      content: '';
      width: 80px;
      height: 10px;
    }
  }
}

.form-control-simu {
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.home-search {
  background-color: $gray-100;
  padding-bottom: 20px;
  border-bottom: 1px solid $gray-300;
  margin-bottom: 20px;

  .form-control-simu {
    height: 50px;
  }
}

.info {
  &-detail {
    font-size: 11px;
  }

  &-price {
    color: $primary;
    font-size: 24px;
    font-weight: 600;
  }

  &-healthpass {
    font-weight: 700;
  }

  &-equipment {
    width: 30px;
    height: auto;
  }

  &-baseline {
    font-size: 12px;
  }
}

.wizard {
  .active {
    span {
      background-color: $primary;
      border-color: $primary;
      color: #FFF;
    }

    &:before {
      background-color: $primary;
    }
  }

  span {
    display: block;
    text-align: center;
    width: 30px;
    height: 30px;
    font-size: 16px;
    border-radius: 50%;
    border: 2px solid $gray-300;
    background-color: #FFF;
    font-weight: 700;
  }

  & > div {
    flex: 1 0 auto;
    position: relative;

    .step {
      width: 100%;
      position: relative;
      z-index: 5;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include media-breakpoint-down(sm) {
        font-size: 11px;
      }
    }

    &:before {
      z-index: 1;
      position: absolute;
      display: block;
      flex: 1 0 auto;
      content: '';
      height: 4px;
      width: 100%;
      background-color: $gray-300;
      left: 0;
      top: 15px;
    }
  }
}

.licence {
  &-old, &-new {
    background: 0 center / contain no-repeat;
    @include media-breakpoint-down(sm) {
      background: 0 top / cover no-repeat;
    }
  }

  &-old {
    height: 355px;
    background-image: url('assets/img/oldPermis.png');
  }

  &-2-box {
    padding-left: 40px;
    padding-top: 40px;
    max-width: 218px;
  }

  &-new {
    height: 430px;
    background-image: url('assets/img/newPermis-1.png');

    &.licence-1 {
      @include media-breakpoint-down(sm) {
        background-position-x: -183px;
      }

    }

    &-label {
      padding-top: 148px;
      @include media-breakpoint-down(sm) {
        font-size: 12px;
      }
    }

    &.licence-2 {
      background-image: url('assets/img/newPermis-2.png');
    }
  }
}

.smaller {
  font-size: 0.65rem;
}

.ribbon {
  &-top {
    margin-top: 6px;

    .ribbon-label {
      top: -6px;
      right: 10px;
    }
  }

  &-label {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    padding: 5px 12px;
    position: absolute;
    color: #FFF;
    font-weight: 600;
    font-size: 12px;
  }
}

.carousel {
  &-inner {
    width: 80%;
    margin: 0 auto;

    .review {
      height: 150px;
      overflow: auto;
      text-align: justify;

      &::-webkit-scrollbar {
        width: 12px;
      }

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
      }
    }
  }
}

.text-justify {
  text-align: justify;
}

.required {
  &::after {
    display: inline;
    content: "*";
    color: $danger;
    font-weight: 700;
  }

  &-message {
    text-align: right;
    font-size: 12px;
    font-style: italic;
  }
}
.vr-bold{
  display: inline-block;
  border-radius: 6px;
  width: 4px;
  height: 40px;
  margin-right: 10px;
}
@include media-breakpoint-down(sm) {
  #more-map {
    height: 100px;
  }
}

#powered-stripe {
  svg {
    fill: #FFF;
    max-height: 25px;
  }
}
